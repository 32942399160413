<template>
  <div class="company">
    <div class="tree-box" ref="scroll">
      <a-spin :spinning="spinning">
        <a-tree style="color: #fff" :load-data="onLoadData" :tree-data="treeData" @select="clickTreeNode"  >
          <template slot="custom" slot-scope="item">
            <div v-if="item.type === 1">{{ item.title }}</div>
            <div v-if="item.type === 2">
              <a-icon v-if="item.status === 1" type="video-camera" theme="filled" style="padding-right: 5px; color: #09ec4a" />
              <a-icon v-else type="video-camera" theme="filled" style="padding-right: 6px; color: #727272" />
              {{ item.title }}</div>
            <!-- 其他自定义内容 -->
          </template>
        </a-tree>
      </a-spin>

    </div>
  </div>
</template>
<script>
import {axiosServer} from "@/utils/request";
export default {
  name: "JcCompany",
  data(){
    return{
      treeData: [
        // { title: '海乐社区', key: '0' },
        // { title: '其他社区', key: '1' },
      ],
      spinning: true,
      scrollPosition: 0,
      pageIndex: 1,
      pageSize: 50,
      isAll: false
    }
  },
  created() {
    this.initCompany();
    // this.initDevice();
  },
  mounted() {
    this.$refs.scroll.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.scroll.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    initCompany(){
      axiosServer({
        url: "/jc/jcCompany/list",
        type:"GET",
        params:{
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          field: "id,,,comName,address,provinceName,cityName,areaName,streetName,lat,lng,action",
          column: 'createTime'
        }
      }).then(res => {
        console.log(res)
        if (res.code === 200){
          res.result.records.forEach((item) => {
            this.treeData.push({
              key: item.id,
              title: item.comName,
              lat: item.lat || 0,
              lng: item.lng || 0,
              type: 1,
              scopedSlots: { title: 'custom' }
            })
          });
          if (res.result.total === this.treeData.length){
            this.isAll = true;
          }
        }
      }).finally(() => {
        this.spinning = false;
      })
    },
    handleScroll(){
      // console.log( this.$refs.scroll.scrollHeight)
      let scrollHeight = this.$refs.scroll.scrollHeight;
      let scrollTop =this.$refs.scroll.scrollTop;//0-18
      let clientHeight = this.$refs.scroll.clientHeight;//233
      if ((scrollTop + clientHeight >= scrollHeight) && this.isAll === false) {
        this.pageIndex++;
        this.initCompany();
        //滚动条滚到最底部
        console.log('到底')
      }
    },
    onLoadData(treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        console.log("onLoadData: ", treeNode);

        let arr = [];
        axiosServer({
          url: "/jc/jcDevice/list",
          params: {
            column: 'createTime',
            page: 1,
            pageSize: 150,
            deviceType: 1,
            jcCompanyId: treeNode.dataRef.key || 0,
          },
          type:"GET",
        }).then(res => {
          console.log("device Records",res)
          let monitorDeviceNames = [];
          res.result.records.map((item) => {
            monitorDeviceNames.push({
              deviceName: item.deviceName,
              ip: item.ip
            });
            arr.push(
                {
                  title: item.deviceName,
                  key: item.id,
                  deviceId: item.id,
                  isLeaf: true,
                  url: item.wssUrl,
                  comId: item.jcCompanyId,
                  type: 2,
                  deviceStatus: item.deviceStatus,
                  statusTime: item.statusTime,
                  status: item.deviceStatus,
                  modelType: item.modelType,
                  deviceType: item.deviceType,
                  scopedSlots: { title: 'custom' }
                }
            )
          })
          treeNode.dataRef.children = [...arr];
          this.treeData = [...this.treeData];
          this.$emit("companyChange", {
            type: 1,
            data: {
              comId: treeNode.dataRef.key,
              monitorDevice: monitorDeviceNames,
              position: [ treeNode.dataRef.lng, treeNode.dataRef.lat],
              test: "onLoadData"
            }
          })
          resolve();
        })

        // setTimeout(() => {
        //   treeNode.dataRef.children = [
        //     // { title: '海乐社区充电桩左侧摄像头', key: `${treeNode.eventKey}-0`, type: 2, url: "wss://video.jckjcn.com:443/rtp/34020000001360000001_34020000001320000002.live.flv", isLeaf: true  },
        //     // { title: '海乐社区充电桩左侧摄像头', key: `${treeNode.eventKey}-0`, type: 2, url: "http://192.168.31.201/main_stream.jpeg?A=", isLeaf: true  },
        //     // { title: '海乐社区充电桩右厕摄像头', key: `${treeNode.eventKey}-1`,  type: 2, url: "http://192.168.31.201/main_stream.jpeg?A=",isLeaf: true  },
        //   ];
        //   this.treeData = [...this.treeData];
        //   resolve();
        // }, 1000);
      });
    },

    initDevice(comId){
      axiosServer({
        url: "/jc/jcDevice/list",
        params: {
          column: 'createTime',
          page: 1,
          pageSize: 30,
          deviceType: 1,
          jcCompanyId: comId || 0,
        },
        type:"GET",
      })
    },
    clickTreeNode(selectedKeys, info){

      let type = info.node.dataRef.type;
      let obj = null;
      if (type === 1){
        console.log("clickTreeNode: ", info)
        if (info.node.expanded === false){
          // this.onLoadData(info.node);
          info.node.onExpand();
          return;
        }else {
            let monitorDeviceNames = [];
            for (let i = 0; i < info.node.dataRef.children.length; i++) {
              monitorDeviceNames.push(info.node.dataRef.children[i]);
            }
            obj = {
              comId: selectedKeys[0],
              monitorDevice: monitorDeviceNames,
              position: [info.node.dataRef.lng || 0,info.node.dataRef.lat || 0],
              test: "clickTreeNode"
            }

        }



      }
      if (type === 2){
        obj = {
          playVideoUrl: info.node.dataRef.url,
          comId: info.node.dataRef.comId,
          deviceName: info.node.dataRef.deviceName,
          deviceId: info.node.dataRef.deviceId,
          deviceType:info.node.dataRef.deviceType,
          modelType:info.node.dataRef.modelType,

          // statusTime: info.node.dataRef.statusTime
        }
      }
      this.$emit("companyChange", {
        type: type,
        data: obj
      })
    }
  },
}
</script>



<style scoped lang="scss">
.company{
  padding: 55px 20px 20px;

}
.tree-box{
  height: 850px;
  overflow-y: scroll;
  font-size: 18px;
}
.tree-box::-webkit-scrollbar {
  width: 0px;

}
.tree-box::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(0,0,0,0.2);
}
.tree-box::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0,0,0,0.1);
}
::v-deep .ant-tree{
  font-size: 18px;
}
::v-deep .ant-tree-title {
  color: #fff; /* 红色 */
}
::v-deep .ant-table-tbody{
  color: #fff;
}


/* 覆盖Tree组件节点点击高亮的背景色 */
::v-deep .ant-tree-node-content-wrapper:hover {
  background-color: #77beff !important; /* 修改为你想要的颜色 */
}
::v-deep .ant-tree-node-selected {
  background-color: #41a2fc !important; /* 修改为你想要的颜色 */
}
</style>