<template>
  <div class="monitor-tab">
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab="实时告警">
        <div class="alarm-box">
          <template v-if="alarms.length == 0">
            <a-empty style="height: 100%;background-color: #0f1325; color: #fff" description="暂无记录" />
          </template>
          <template v-else>
            <a-card class="alarm-list" v-for="(item, index) in alarms" :key="index">
              <div>告警时间:{{item.alarmTime}}</div>
              <div>告警单位名称:{{item.jcCompanyId_dictText}}</div>
              <div>告警设备名称:{{item.deviceId_dictText}}</div>
              <div>告警类型:{{ item.alarmTypeText }}</div>
              <div v-if="item.alarmTypeText === '温度告警'">告警值:{{item.alarmVal}}</div>
<!--              <template v-if="item.jcDeviceType == 2">-->
<!--                <div>告警传感：{{item.sensor}}</div>-->
<!--                <div>告警值：{{item.value}}</div>-->
<!--              </template>-->
              <template v-if="item.jcDeviceType == 1">

                <div style="margin-top: 5px">
                  <template v-for="(pic, i) in item.pictureUrl.split(',')" >
                    <img :src="pic" v-if="pic" :preview="item.id + '-' + i" :key="i">
                    <a-empty style="width:340px; height: 180px;background-color: #787a85; color: #fff" description="暂无图片" v-else :key="i"/>
                  </template>
<!--                  <img :src="item.pictureUrl" v-if="item.pictureUrl" :preview="item.id" >-->
<!--                  <a-empty style="width:340px; height: 180px;background-color: #787a85; color: #fff" description="暂无图片" v-else />-->

                </div>
              </template>
              <template v-if="item.jcDeviceType == 2">
                <div>告警值:{{ item.alarmVal }}</div>

              </template>

              <div style="display: flex; justify-content: space-between; margin-top: 8px; width: 345px">
                <a-button type="primary" style="width: 165px" @click="openAlarmDialog(item)">
                  查看视频
                </a-button>

                <a-popconfirm
                    title="是否执行一键灭火功能"
                    ok-text="执行"
                    cancel-text="取消"
                    @confirm="clickFireExtinguishing(item)"
                >
                  <a-button type="danger"  style="width: 165px">
                    一键灭火
                  </a-button>
                </a-popconfirm>
              </div>
            </a-card>
          </template>

        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="告警任务" force-render>
        <div class="alarm-box">
          <div style="display: flex"><a-icon type="undo" class="undo" title="刷新列表" @click="initAlarmJobs" /></div>
          <template v-if="alarmJobs.length == 0">
            <a-empty style="height: 100%;background-color: #0f1325; color: #fff" description="暂无记录" />
          </template>
          <template v-else>
            <a-card :loading="jobLoading" class="alarm-list" v-for="(item, index) in alarmJobs" :key="index">
              <div>设备名称:{{item.deviceId_dictText}}</div>
              <div>执行状态:{{item.jobStatus_dictText}}</div>
              <div>任务创建时间:{{item.createTime}}</div>
              <template v-if="item.jobStatus == 1">
                <div style="display: flex; justify-content: space-between; margin-top: 8px; width: 345px">
                  <a-popconfirm
                      title="是否解除告警"
                      ok-text="确认解除"
                      cancel-text="取消"
                      @confirm="removeAlarmJob(item)"
                  >
                    <a-button type="primary" style="width: 165px" >
                      解除告警
                    </a-button>
                  </a-popconfirm>
                  <a-popconfirm
                      title="是否启动一键灭火功能"
                      ok-text="执行"
                      cancel-text="取消"
                      @confirm="autoSendOrderByAlarmJob(item)"
                  >
                    <a-button type="danger" style="width: 165px" >
                      确认告警
                    </a-button>
                  </a-popconfirm>

                </div>
              </template>
              <template v-if="item.jobStatus == 3">
                <div>任务执行人:{{item.actionUser_dictText || 暂无}}</div>
                <div>任务执行时间:{{item.jobActionTime}}</div>
              </template>
              <template v-if="item.jobStatus == 0">
                <div>任务解除人:{{item.removeUser_dictText}}</div>
                <div>任务解除时间:{{item.removeTime}}</div>

              </template>
              <template v-if="item.jobStatus == 2">
                <div>任务执行人:{{item.actionUser_dictText}}</div>
                <div>任务执行时间:{{item.jobActionTime}}</div>
              </template>
            </a-card>
          </template>
        </div>

      </a-tab-pane>
<!--      <a-tab-pane key="2" tab="录像列表" force-render>-->
<!--        <div class="record-box">-->
<!--          <a-card class="record-list" v-for="(item, index) in records" :key="index">-->
<!--            <div class="record-list-item">-->
<!--              <div style="line-height: 30px">录像列表：{{index}}</div>-->
<!--              <div style="display: flex">-->
<!--                <a-button type="link">播放</a-button>-->
<!--                <a-button type="link">下载</a-button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div>-->
<!--             <div>-->
<!--               开始时间：{{item.startTime}}-->
<!--             </div>-->
<!--              <div>-->
<!--                结束时间：{{item.endTime}}-->
<!--              </div>-->
<!--            </div>-->
<!--          </a-card>-->
<!--        </div>-->

<!--      </a-tab-pane>-->
    </a-tabs>

  </div>
</template>

<script>
import {axiosServer} from "@/utils/request";

export default {
  name: "JcMonitorTab",
  data() {
    return {
      mode: 'top',
      alarms: [],
      alarmJobs:[],
      jobLoading: false
    };
  },
  mounted() {
    this.initAlarms();
    this.initAlarmJobs();
  },
  methods: {
    initAlarms(){
      axiosServer({
        url: "/jc/jcAlarm/list",
        params: {
          column: 'createTime',
          page: 1,
          pageSize: 20,
          order: "desc"
        },
        type:"GET",
      }).then(res => {
        console.log("initAlarms：",res);
        if (res.code === 200){
          this.alarms = res.result.records;
        }

      })
    },
    initAlarmJobs(){
      this.jobLoading = true;
      axiosServer({
        url: "/jc/jcAlarmJob/list",
        params: {
          column: 'createTime',
          page: 1,

          pageSize: 40,
          order: "desc"
        },
        type:"GET",
      }).then(res => {
        console.log("initAlarmJobs：",res);
        if (res.code === 200){
          this.alarmJobs = res.result.records;
        }else {
          this.alarmJobs = [];
        }

      }).catch(()=>{
        this.alarmJobs = [];
      }).finally(()=>{
        this.jobLoading = false;
      })
    },
    tabChange(val) {
      console.log(val);
    },
    openAlarmDialog(record){

      this.$emit("openAlarm", record);
    },
    clickFireExtinguishing(record){
      console.log("clickFireExtinguishing",record)
      axiosServer({
        url: "/jc/jcGeneral/fire/extinguishing",
        type: "POST",
        params: {
          deviceId: record.deviceId
        }
      }).then(res =>{
        if (res.success){
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      }).catch(()=>{
        this.$message.error("下发错误")
      })
    },
    autoSendOrderByAlarmJob(record){
      axiosServer({
        url: "/jc/jcGeneral/auto/alarmJob",
        type: "POST",
        params: {
          deviceId: record.deviceId,
          jobId: record.id
        }
      }).then(res =>{
        if (res.success){
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      }).catch(()=>{
        this.$message.error("下发错误")
      }).finally(() => {
        this.initAlarmJobs();
      })
    },
    removeAlarmJob(record){
      axiosServer({
        url: "/jc/jcGeneral/remove/alarmJob",
        type: "POST",
        params: {
          deviceId: record.deviceId,
          jobId: record.id
        }
      }).then(res =>{
        if (res.success){
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      }).catch(()=>{
        this.$message.error("下发错误")
      }).finally(() => {
        this.initAlarmJobs();
      })
    }
  },
}
</script>

<style scoped lang="scss">
.monitor-tab{
  width: 430px;
  padding: 20px;
  margin: 0 auto ;

}
::v-deep .ant-tabs-tab {
  color: #fff; /* 你想要的字体颜色 */
}
.record-box{
  height: 800px;
  overflow-y: scroll;

  .record-list{
    color: #fff;
    //height: 70px;
    font-size: 16px;
    margin-bottom: 5px;
    background-color: transparent;
    .record-list-item{
      display: flex;
      justify-content: space-between;
      height: 30px;
    }
  }
}
.record-box::-webkit-scrollbar {
  width: 0px;

}
.record-box::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(0,0,0,0.2);
}
.record-box::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0,0,0,0.1);
}


.alarm-box{
  height: 800px;
  overflow-y: scroll;
  .alarm-list{
    width: 100%;
    background-color: transparent;
    color: #fff;

    font-size: 16px;
    margin-bottom: 5px;
    display: flex;
    .alarm-left{
      flex: 1;
    }
    .alarm-right{
      flex: 1;

    }
    img{
      width: 340px;
      height: 180px;
    }
    ::v-deep .ant-empty{
      margin: 0;
    }
  }
  ::v-deep .ant-card-bordered{
    //border: 1px solid #f5222d;
  }
}
.alarm-box::-webkit-scrollbar {
  width: 0px;
}
.alarm-box::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(0,0,0,0.2);
}
.alarm-box::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0,0,0,0.1);
}
.undo{
  margin-left: auto;
  color: white;
  margin-bottom: 8px;
  cursor: pointer;
}
.undo:hover{

  color: #77beff;

}

</style>