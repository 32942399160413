<template>
  <div class="webrtc-box">
    <div v-if="timerLineVisible" class="timer-line">

<!--      <div>-->
<!--        <div>回放开始时间:</div>-->
<!--        <a-date-picker-->
<!--            @change="onChangeByDate"-->
<!--            @openChange="openChangeByDate"-->
<!--            :disabled-date="disabledDate"-->

<!--            @ok="onOk" />-->
<!--        <a-time-picker @change="onChange"  :default-value="moment('00:00:00', 'HH:mm:ss')"   format="HH:mm:ss"  :allowClear="false"   v-model="currTime"  />-->
<!--        <a-button type="link" @click="playLookBack">播放</a-button>-->
<!--&lt;!&ndash;        <a-button type="link" @click="showTime">回显时间</a-button>&ndash;&gt;-->
<!--        <div id="wrap" >-->
<!--          <div id="timeSlider" ref="timeSlider">-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


    </div>
    <div style="display: flex">
      <a-button type="link" @click="play">播放</a-button>
<!--      <span v-show="recordStatus === 1">自动录像中</span>-->
<!--      <span v-show="recordStatus === 2">录制结束</span>-->
<!--      <span v-show="recordStatus === 3">视频播放失败,录制异常</span>-->
<!--      <span v-show="recordStatus === 3">自动录像功能未开启</span>-->
<!--      <a-button type="link" @click="lookBack">回看</a-button>-->
    </div>
    <video ref="video1"  width="650" height="350" controls autoplay muted style="  background-color: #000000;"> </video>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "JcWebrtcByBoli",
  props: ['videoUrl',"operationType"],
  data(){
    return{
      sdk: null,
      timerLineVisible: false,
      config: {
        username: 'admin',
        passwd: '12345678',
        channelid: "1", // 0主码流 1子码流,
        nettype: 'ipv4',
        // retry: 0,
        url: null
      },
      avaiableDateList: [],
      currDate: null,
      currTime: moment('00:00:00', 'HH:mm:ss'),
      markTimes: [],
      timeSliderInstance: null,
      timelineShow: false,


      recordStatus: 1,
      recordBuffer: [],
      mediaRecorder: null
    }
  },
  mounted() {

    this.play()
    // let promise =  this.play();
    // if (promise !== undefined){
    //   promise.then( () => {
    //     this.play();
    //     // console.log("浏览器禁止自动播放音频")
    //   }).catch(e => {
    //     this.play();
    //     console.log("发生错误", e)
    //   })
    // }

  },
  methods:{
    moment,
    showTime(){
      this.currTime = moment("12:11:15", "HH:mm:ss");
    },
    onOk(value){
      console.log('onOk: ', value);
    },
    onChange(time, timeString){
      console.log("onChange", time)
      console.log("onChange", timeString)
      this.currTime = moment(timeString, "HH:mm:ss");
    },
    onChangeByDate(date, dateString){
      // console.log("onChangeByDate", e)
      console.log('openChangeByDate ', date)
      console.log('openChangeByDate ', dateString)
      this.currDate = dateString;

      this.requestTimeinfo(dateString);
    },
    openChange(e){

      console.log("openChange", e)
    },
    openChangeByDate(e){
      console.log("openChange", e)
      if (e){
        // this.timelineShow = false;

      }

      // this.requestTimeinfo(e);
    },
    disabledDate(current){
      let date = moment(current).format("YYYY-MM-DD");
      return this.avaiableDateList.indexOf(date) === -1;

    },
    play(){
      // sdk 存在先销毁
      if (this.sdk) {
        this.sdk.close();
      }
      // eslint-disable-next-line no-undef
      this.sdk = new WRtcPlayerAsync();
      console.log("sdk",this.sdk);
      console.log("startPlay url:", this.videoUrl);
      this.$refs.video1.srcObject = this.sdk.stream;
      this.config.url = this.videoUrl;
      this.config.channelid = "1";
      this.config.apiUrl = "https://wrtc.dvr168.org:1990/rtc/v1/play/";
      this.sdk.play(this.config).then(res => {
        console.log("sdk play", res)
        console.log(" operationType", this.operationType)
        if (this.operationType === 1){
          //
          // this.startRecord();
          // this.stopRecordDelay(15 * 1000);
        }else {
          this.recordStatus = 4;
        }
      }).catch(e => {
        console.log("webrtc异常:" ,e)
        this.sdk.close();
      })
    },
    lookBack(){
      this.timerLineVisible = !this.timerLineVisible;
      if (this.timerLineVisible){
        this.$nextTick(()=>{
          this.updateTimeline();
        })
        // this.timelineShow = true;
        this.requestFileDays();
      }
    },
    playLookBack(){
      if (!this.currDate){
        this.$message.warn("请选择日期");
        return;
      }
      if (!this.currTime){
        this.$message.warn("请选择时间");
        return;
      }
      console.log("=============this.currTime._i, this.currDate===========")
      console.log(this.currTime, this.currDate,  this.currTime._i)
      if (this.sdk) {
        this.sdk.close();
      }
      // eslint-disable-next-line no-undef
      this.sdk = new WRtcPlayerAsync();
      console.log("sdk",this.sdk);
      console.log("startPlay url:", this.videoUrl);
      this.$refs.video.srcObject = this.sdk.stream;
      this.config.channelid = "3";
      this.config.url = this.videoUrl;
      this.config.starttime = this.currDate + "-" + this.currTime._i;
      this.config.apiUrl = "https://wrtc.dvr168.org:1990/rtc/v1/play/";
      this.sdk.play(this.config).then(res => {
        console.log("sdk play", res)
      }).catch(e => {
        console.log("webrtc异常:" ,e)
        this.sdk.close();
      })
    },
    requestFileDays(){
      let schema = 'https:';
      let defaultPath = '/api/v1/device/getfiledays';
      let port = '1990';
      let ip = 'wrtc.dvr168.org';

      let apiUrl = schema + '//' + ip + ':' + port + defaultPath;
      let streamIdStr = this.videoUrl.split("/");
      let streamId = streamIdStr[streamIdStr.length - 1];
      let data = {
        type: 1,
        namespaceId: "TESTWRTC",
        namespaceName: "TESTWRTC",
        deviceId: streamId,
        name: "device1",
        desc: "test wrtc",
        username: "admin",
        password: "123456",
        location: {
          enable: true,
          type: 1,
          longitude: 0,
          latitude: 0
        },
        date: "2024-06-28"
      };
      this.getFileDays(apiUrl, data);

    },
    async getFileDays(apiUrl, config){
      let that = this;
      await new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.readyState !== xhr.DONE) return;
          if (xhr.status !== 200) return reject(xhr);
          const data = JSON.parse(xhr.responseText);
          console.log("Got answer: ", data);
          if (data.count > 0){
            console.log("filedays: ", data.filedays);
            that.avaiableDateList = data.filedays;
            // markedDates.length = 0;
            // markedDates = data.filedays.slice();
            // console.log("markedDates: ", markedDates);
            // updateMarkedDate();
          }
          return data.code ? reject(xhr) : resolve(data);
        }
        xhr.open('POST', apiUrl, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.send(JSON.stringify(config));
      });
    },
    requestTimeinfo(dateString){
      let schema = 'https:';
      let defaultPath = '/api/v1/device/gettimeinfo';
      let port = '1990';
      let ip = 'wrtc.dvr168.org';
      let apiUrl = schema + '//' + ip + ':' + port + defaultPath;
      let streamIdStr = this.videoUrl.split("/");
      let streamId = streamIdStr[streamIdStr.length - 1];


      let data = {
        type: 1,
        namespaceId: "TESTWRTC",
        namespaceName: "TESTWRTC",
        deviceId: streamId,
        name: "device1",
        desc: "test wrtc",
        username: "admin",
        password: "123456",
        location: {
          enable: true,
          type: 1,
          longitude: 0,
          latitude: 0
        },
        date: dateString
      };
      this.getTimeinfo(apiUrl, data);
    },
    async getTimeinfo(apiUrl, config){
      let that = this;
       await new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.readyState !== xhr.DONE) return;
          if (xhr.status !== 200) return reject(xhr);
          const data = JSON.parse(xhr.responseText);
          console.log("Got answer: ", data);
          if (data.count > 0){
            that.markTimes = data.timeinfo;

            that.$nextTick(()=>{

              that.updateTimeline();
            })
            // console.log("timeinfo: ", data.timeinfo);
            // iframeTimeline.contentWindow.postMessage(data, '*');
          }
          return data.code ? reject(xhr) : resolve(data);
        }
        xhr.open('POST', apiUrl, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.send(JSON.stringify(config));
      });
    },
    updateTimeline(){


      if (this.timeSliderInstance) {
        let element = this.$refs.timeSlider;
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
        //
        // this.timelineShow = false;
        // this.timeSliderInstance = null;

        // document.body.innerHTML = '';
        // const wrapDiv = document.createElement('div');
        // wrapDiv.id = 'wrap';
        // const timeSliderDiv = document.createElement('div');
        // timeSliderDiv.id = 'timeSlider';
        // wrapDiv.appendChild(timeSliderDiv);
        // document.body.appendChild(wrapDiv);
      }
      // this.timelineShow = true;
      let that = this;
      let _moment = moment;
      // eslint-disable-next-line no-undef
      this.timeSliderInstance = new initTimeSlider('#timeSlider', {
        curDaytimeChunkArray: this.markTimes,
        presentSeconds: 20,
        timeChunkType: {
          A: 'red',            //alarm
          B: 'yellow',         //
          C: 'green'            //normal
        },
        speed: 0,
        isInitialPlay: false,
        onClick(oldSeconds, newSeconds) {
          // console.log('onClick', oldSeconds, newSeconds);
          let hour = Math.floor(newSeconds / 3600);
          let min = Math.floor((newSeconds % 3600) / 60);
          let sec = Math.floor((newSeconds % 3600) % 60);

          // console.log("time", newSeconds, hour, min, sec);
          // window.parent.document.getElementById("IDPlaybackHourStart").value = hour;
          // window.parent.document.getElementById("IDPlaybackMinStart").value = min;
          // window.parent.document.getElementById("IDPlaybackSecStart").value = sec;
          // let data = {
          //   hour: hour,
          //   min: min,
          //   sec: sec
          // };

          that.currTime = _moment(String(hour).padStart(2, "0") + ":" + String(min).padStart(2, "0") + ":"+ String(sec).padStart(2, "0"), "HH:mm:ss");
          console.log(hour, min, sec, that.currTime)
          // console.log(data)
          // window.parent.postMessage(JSON.stringify(data), '*');
        },
        onMove() {
          // console.log('onMove')
        },
        onMouseDown() {
          // console.log('onMousedown')
        }
      })

    },
    startRecord(){
      console.log("开始自动录制")
      this.recordStatus = 1;
      this.recordBuffer = [];
      this.mediaRecorder = null;

      let options = {
        mimeType: 'video/webm;codecs=h264'
      };

      if(!MediaRecorder.isTypeSupported(options.mimeType)){
        this.$message.error("您的浏览器不支持录制回放功能")
        return;
      }
      try{
        console.log("this.sdk.stream")
        this.mediaRecorder = new MediaRecorder(this.sdk.stream, options)
      }catch (e) {
        console.log('Filed tp create MideaRecoder',e);
        return;
      }

      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.start(10);



    },
    handleDataAvailable(e) {
      if(e&& e.data && e.data.size > 0){
        this.recordBuffer.push(e.data);
      }
    },
    stopRecord(){
      console.log("结束录制")
      this.recordStatus = 2;
      if (this.mediaRecorder){
        this.mediaRecorder.stop();
      }

      this.downloadVideo();
    },
    stopRecordDelay(time){
      console.log("结束录制")
      setTimeout(() => {
        this.recordStatus = 2;
        if (this.mediaRecorder){
          this.mediaRecorder.stop();
        }

        this.downloadVideo();
      }, time)
    },
    stopRecordNoDownload(){
      if (this.mediaRecorder){
        this.mediaRecorder.stop();
      }
      this.mediaRecorder = null;

      this.recordStatus = 0;
      this.recordBuffer = [];
    },
    downloadVideo(){
      let blob = new Blob(this.recordBuffer,{type: 'video/webm'});
      if (blob.size === 0){
        this.recordStatus = 3;
        return;
      }
      console.log("blob", blob);
      let url = window.URL.createObjectURL(blob);

      let filename =  "告警视频" + new Date().getTime() + ".webm";
      console.log(filename);
      let a = document.createElement('a');
      a.href = url;
      a.style.display = 'none';
      a.download = filename;
      a.click();
    }
  },
  watch: {
    videoUrl: {
      handler(newVal, oldVal) {

        if (newVal !== oldVal){
          this.$nextTick(()=>{
            this.play();

          })
        }

      },
      immediate: true
    },

  },
  destroyed() {
    console.log("webrtc销毁")
    this.sdk.close();
  }
}
</script>



<style scoped lang="scss">
.webrtc-box{
  position: relative;

}
.timer-line{
  width: 600px;
  height: 150px;
  border: 1px solid #f5222d;
  background-color: #0f1325;
  position: absolute;
  top: -150px;
  left: 0;
}
video{
  object-fit: fill;
}
::v-deep .ant-btn{
  padding: 0 6px;
}
</style>