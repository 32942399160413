import {axiosServer} from "@/utils/request";
import Vue from "vue";

console.log(Vue.prototype)

export const sendOrder = (data, order) => {

    // if (order.orderType === 1){
    //     axiosServer({
    //         url: "/jc/jcGeneral/auto/send",
    //         params: {
    //             ip: data.ip,
    //             val: order.val,
    //             orderType: order.orderType,
    //
    //         },
    //         type:"POST",
    //     }).then(res => {
    //         if (res.code === 200){
    //             Vue.prototype.$notification.success({
    //                 message:res.message
    //             });
    //         }else {
    //             Vue.prototype.$notification.error({
    //                 message:res.message
    //             });
    //         }
    //     })
    //     return;
    // }

    let config = {

    };

    if (data.actionType === 1){
        config.ip = data.ip;
        config.operationCode = order.val;
    }
    if (data.actionType === 2){

        config.ip = data.ip;
        config.eqSwitch = order.val;
        config.equipId =  data.equipid;
        config.controlName =  data.deviceName;
    }

    axiosServer({
        url: "/jc/jcGeneral/send/order",
        params: {
            ...config
        },
        type:"POST",
    }).then(res => {
        if (res.code === 200){
            Vue.prototype.$notification.success({
                message:res.message
            });
        }else {
            Vue.prototype.$notification.error({
                message:res.message
            });
        }
    })


}



/**
 * 获取区域对角线的两点坐标，即这个区域内的最小坐标值和最大坐标值
 *
 * @param pointerArray [[a,b],[c,d]]
 * @return Array {min:number[a,b], max:number[c,d]}
 */
export const getMaxBoundsPointer = (pointerArray) => {
    let lngArray = pointerArray.map(item => item[0])
    let latArray = pointerArray.map(item => item[1])

    return {
        min: [Math.min(...lngArray),  Math.min(...latArray)],
        max: [Math.max(...lngArray),  Math.max(...latArray)],
    }
}