<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">大屏可视化监控平台</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>

              <span class="text" style="margin-right: 8px">
                欢迎用户:{{ userInfo.realname }}

              </span>
              <span class="text">
                 <a-dropdown>
                    <a class="ant-dropdown-link" style="color: #1890ff" @click="e => e.preventDefault()">
                      操作 <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a @click="switchSystem(0)">切换监控版</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a @click="switchSystem(1)">切换地图版</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a  @click="handleSubmit">退出登录</a>
                      </a-menu-item>
                      <!--                      <a-menu-item>-->
                      <!--                        <a href="javascript:;">3rd menu item</a>-->
                      <!--                      </a-menu-item>-->
                    </a-menu>
              </a-dropdown>
              </span>
            </div>
          </div>
        </div>
        <!-- 第二行 -->
        <template v-if="systemType === 0">

          <div class="display-box">
            <div class="left">
              <dv-border-box-11 title="单位">
                <JcCompany @companyChange="companyChange"></JcCompany>
              </dv-border-box-11>
            </div>
            <template v-if="pageStatus === 0">
              <div class="center">
                <div class="monitor">
                  <dv-border-box-12>
                    <JcMonitor
                        ref="monitor"
                        :play-video-url="playVideoUrl"
                        :device-status="deviceStatus"
                        :device="currentDevice"
                        :comId="currenComId"
                        :comPos="currenComPos"
                    ></JcMonitor>

                  </dv-border-box-12>
                </div>
                <div class="device">
                  <dv-border-box-12>
                    <JcDevice ref="device" :monitor-device-name="monitorDeviceName"></JcDevice>
                  </dv-border-box-12>
                </div>
              </div>
              <div class="right">
                <dv-border-box-8>
                  <JcMonitorTab ref="monitorTab" @openAlarm="getOpenAlarm"></JcMonitorTab>
                </dv-border-box-8>
              </div>
            </template>
            <!--          <template v-if="pageStatus === 1">-->
            <!--            <div class="alarm">-->
            <!--              <dv-border-box-7 backgroundColor="#f5222d60">-->
            <!--                <JcAlarm :alarm-list="alarmList"></JcAlarm>-->
            <!--              </dv-border-box-7>-->
            <!--            </div>-->

            <!--          </template>-->

          </div>
        </template>

        <template v-if="systemType === 1">
          <div style=" height: 900px; margin-top: 10px">
            <dv-border-box-12>
              <MapSystem ref="mapSystem"></MapSystem>
            </dv-border-box-12>
          </div>
        </template>

      </div>
    </div>

      <a-modal
          style="z-index: 9999!important;"
          width="1050px"
          :visible="videoDialogVisible"
          @cancel="videoCancel"
          @ok="videoOk"
          :footer="null"
          :centered="true"
          :maskClosable="false"
      >

        <JcAlarmDialog v-if="videoDialogVisible"
                       :video-alarm="videoAlarm"
                       :video-alarm-id="videoAlarmId"
                       :operation-type="operationType" ref="alarmDialog"
                       @refreshAlarmJob="refreshAlarmJob"
        ></JcAlarmDialog>


      </a-modal>


  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
// import centerLeft1 from './centerLeft1'
// import centerLeft2 from './centerLeft2'
// import centerRight1 from './centerRight1'
// import centerRight2 from './centerRight2'
// import center from './center'
// import bottomLeft from './bottomLeft'
// import bottomRight from './bottomRight'
import JcCompany from "./company/JcCompany.vue";
import JcMonitorTab from "@/views/monitor/JcMonitorTab.vue";
import JcMonitor from "@/views/monitor/JcMonitor.vue";
import JcDevice from "@/views/device/JcDevice.vue";
import JcAlarmDialog from "@/views/alarm/JcAlarmDialog1.vue";
// import JcAlarm from "@/views/alarm/JcAlarm.vue";
import {axiosServer} from "@/utils/request";
import router from "@/router";
import MapSystem from "@/views/MapSystem.vue";
import moment from "moment";
export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      playVideoUrl: null,
      pageStatus: 0,
      currenComId: 0,
      currenComPos: [],
      alarmList: [],
      videoDialogVisible: false,
      monitorDeviceName: [],
      videoAlarm: {},
      videoAlarmId: null,
      deviceStatus: 0,
      systemType: parseInt(localStorage.getItem("systemType")) || 0, // 0监控系统版 1地图大屏版
      lockReconnect: true,
      reconnectTime: 0,
      loginStatus: true,
      operationType: 0,
      currentDevice: null
    }
  },
  components: {
    JcDevice,
    JcCompany,
    JcMonitorTab,
    JcMonitor,
    JcAlarmDialog,
    MapSystem
    // JcAlarm
    // centerLeft1,
    // centerLeft2,
    // centerRight1,
    // centerRight2,
    // center,
    // bottomLeft,
    // bottomRight
  },
  mounted() {
    if (this.systemType !== 1){
      window.addEventListener('resize', this.resize)
    }
    this.initWebSocket();
    this.timeFn();
    this.cancelLoading();
  },
  destroyed: function () { // 离开页面生命周期函数
    // this.websocketOnclose();
    this.websocketClose();
    clearInterval(this.timing);
  },
  methods: {


    initWebSocket: function () {
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      this.reconnectTime = 0;
      console.log("this.$baseUrl",this.$baseUrl)
      var userId = "display-"   + this.userInfo.id;
      var url = this.$baseUrl.wsUrl +"/websocket/"+ userId  ;
      //console.log(url);
      //update-begin-author:taoyan date:2022-4-22 for:  v2.4.6 的 websocket 服务端，存在性能和安全问题。 #3278
      let token = localStorage.getItem("assToken");
      this.websock = new WebSocket(url, [token]);
      //update-end-author:taoyan date:2022-4-22 for:  v2.4.6 的 websocket 服务端，存在性能和安全问题。 #3278
      this.websock.onopen = this.websocketOnopen;
      this.websock.onerror = this.websocketOnerror;
      this.websock.onmessage = this.websocketOnmessage;
      this.websock.onclose = this.websocketOnclose;

    },

    websocketClose(){
      this.websock.close();
      this.lockReconnect = false;
      console.debug("websocket关闭")
    },
    websocketOnopen: function () {
      console.debug("WebSocket连接成功");
      this.lockReconnect = true;
      //心跳检测重置
      //this.heartCheck.reset().start();
    },
    websocketOnerror: function () {
      // console.debug("WebSocket连接发生错误，第%s次",this.wsConnectErrorTime);
      //
      // this.wsConnectErrorTime = this.wsConnectErrorTime + 1;
      // if(this.wsConnectErrorTime>5){
      //   console.debug("WebSocket连接错误超过5次，就不再重新连了！");
      //   this.lockReconnect = true
      //   return;
      // }
      //
      // this.reconnect();
      this.reconnectTime++;
      this.lockReconnect = false;
      console.log("websocketOnerror连接发生错误",moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
      // this.reconnect();
    },
    websocketOnmessage: function (e) {

      console.log("-----接收消息-------");

      var data = eval("(" + e.data + ")"); //解析对象
      console.log("websocket data", data)

      if (data.cmd === "alarm"){
        let alarmData = JSON.parse(data.data);
        console.log("data.cmd === \"alarm\"",alarmData)
        // 关闭当前预览的图片
        if (this.$preview.self){
          this.$preview.self.close();
        }

        if (this.$refs.monitor){
          this.$refs.monitor.webrtcClose();
        }
        // 解析数据


        // 打开告警弹框
        this.videoChange(alarmData, alarmData.alarm.id, 1);

        // 刷新实时列表数据
        if (this.systemType === 0){
          this.$refs.monitorTab.initAlarms();
          this.$refs.monitorTab.initAlarmJobs();
        }
        if (this.systemType === 1){
          this.$refs.mapSystem.initAlarms();
          this.$refs.mapSystem.initCompanyInfo(alarmData.company.id, true)
        }

        // 语音播报
        let deviceName = alarmData.device.deviceName || "未知设备";
        let str = deviceName + "发生告警";
        this.$speech.speak({ text: str }).then(() => {
          console.log("播报成功" + `设备${deviceName}发送告警`);
        }).catch(e => {
          console.log(e)
        })
        this.$notification.error({
          message: str,
          duration: 3,
        })
      }

      //心跳检测重置
      //this.heartCheck.reset().start();
    },


    websocketOnclose: function (e) {
      console.log("websocketOnclose连接错误 (" + e + ")", this.reconnectTime, this.loginStatus);
      this.lockReconnect = false;
      if (this.loginStatus){
        this.reconnect();
      }

      // this.reconnect();
    },
    reconnect() {

      if (this.lockReconnect) return;
      console.log("websocket重连")
      // this.websock.close();
      this.initWebSocket();
      // var that = this;
      // if(that.lockReconnect) return;
      // that.lockReconnect = true;
      // //没连接上会一直重连，设置延迟避免请求过多
      // setTimeout(function () {
      //   console.debug("websocket 尝试重连...");
      //   that.websock = null;
      //   that.initWebSocket();
      //   that.lockReconnect = false;
      // }, 20000);
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    Logout(){
      axiosServer({
        url: '/sys/displayLogout',
        type: 'POST',
        params: {

        }
      }).then(res => {
        console.log(res)
        if (res.code === 200){
          // 设置属性
          // this.websocketOnclose();
          this.websocketClose();
          localStorage.removeItem("assToken");
          localStorage.removeItem("userInfo");

          this.removeLogo();
          // 提示登录成功
          this.$notification.success({
            message:res.message || "登出成功"
          });


        }else {
          this.$notification.error({
            message:res.message || "登出失败"
          });
        }
        // 页面跳转
        router.push("/")
      }).finally(() => {
        this.websock = null;
        this.loginStatus = false;
      })
    },
    handleSubmit(){
      const that = this

      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        okText: "退出登录",
        cancelText: "取消",
        onOk() {
          that.Logout({});
        },
        onCancel() {
        },
      });

    },
    removeLogo(){
      let link = document.querySelector("#link_logo");
      if (link){
        document.getElementsByTagName('head')[0].removeChild(link);
      }
    },
    companyChange(getData){
      if (this.pageStatus === 1){
        return;
      }
      console.log("getData",getData)
      if (getData.type === 1){
        this.monitorDeviceName = getData.data.monitorDevice;
        if (getData.data.comId){
          this.currenComId = getData.data.comId;
          this.currenComPos = getData.data.position;
        }

        this.$nextTick(() => {

          this.$refs.monitor.orderDevice = []

          if (Number(localStorage.getItem("usePos")) === 2){
            this.$refs.monitor.initDevicePos();
          }else {
            this.$refs.monitor.clickCompanyChange(getData.data.position);
          }
        })

        this.playVideoUrl = "";
        this.deviceStatus = 0;
        this.currentDevice = null;
        // 切换单位获取单位告警信息

        // 关闭摄像头
      }

      if (getData.type === 2){
        console.log("getData111",getData.data)
        // 切换摄像头
        // 摄像头推流
        // this.$refs.device.initDevice(getData.data.comId)
        this.currenComId = getData.data.comId;
        this.deviceStatus = getData.data.deviceStatus;
        this.$refs.monitor.initRelationDevice(getData.data.deviceId);
        this.currentDevice = getData.data;
        if (this.playVideoUrl.includes("120.226.39.61")){
          this.playVideoUrl = getData.data.playVideoUrl + (new Date().getTime());
        }else {
          this.playVideoUrl = getData.data.playVideoUrl;
        }

      }

    },
    videoOk(){
      this.videoDialogVisible = false;
    },
    videoCancel(){
      this.videoDialogVisible = false;
    },
    videoChange(data, id, operationType){
      this.videoDialogVisible = true;
      this.videoAlarm = data;
      this.videoAlarmId = id;
      this.operationType = operationType;
      this.$nextTick(()=>{
        console.log(" videoChange",data)
        this.$refs.alarmDialog.currentVideoUrl = data.monitors[0].wssUrl;
        this.$refs.alarmDialog.initDevice1(data.company.id);
        this.$refs.alarmDialog.initRelationDevice(data.monitors[0].id);
        this.$refs.alarmDialog.alarmJob = data.job;
      })
    },

    getOpenAlarm(record){
      console.log("getOpenAlarm",record);
      axiosServer({
        url: "/jc/jcGeneral/alarm/monitor",
        type: "POST",
        params: {
          deviceId:record.deviceId,
          deviceType: record.jcDeviceType,
          modelType: record.jcModelType,
          comId: record.jcCompanyId,
          deviceIp: record.deviceIp
        }
      }).then(res => {
        console.log("getOpenAlarm",res)
        if (res.success){
          let obj = {
            device: {
              deviceId: record.deviceId,
              deviceName:record.deviceId_dictText,
              deviceType: record.jcDeviceType,
              modelType: record.jcModelType
            },
            alarm: record,
            monitors: res.result.monitors,
            company: {
              id: record.jcCompanyId,
              comName: record.jcCompanyId_dictText
            },
            job: res.result.job || {}
          }
          console.log("getOpenAlarm", obj)
          this.videoChange(
              obj, record.id, 2
          );
        }else {
          this.$message.error("查看失败");
        }
      }).catch(() => {
        this.$message.error("查看失败");
      })
    },
    switchSystem(type){
      if (this.systemType === type){
        this.$message.warn("已经在所切换的系统中")
        return;
      }

      this.systemType = type;
      localStorage.setItem("systemType", type);
      if (type === 1){
        window.removeEventListener('resize', this.resize)
      }else {
        window.addEventListener('resize', this.resize)
      }
    },
    refreshAlarmJob(){
      console.log("refreshAlarmJob")
      if (this.systemType === 0){
        // this.$refs.monitorTab.initAlarms();
        this.$refs.monitorTab.initAlarmJobs();
      }
    }
  },
  watch:{
    currenComId(newVal, oldVal){
      if (newVal !== oldVal){
        console.log(newVal, oldVal)
        this.$nextTick(() => {
          // this.$refs.device.initDevice(newVal, this.monitorDeviceName);
          this.$refs.device.initDevice1(newVal);
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.display-box{
  display: flex;
  padding-top: 20px;
  height: 940px;
  .left{
    //flex: 1;
    width: 455px;
    padding: 4px;


  }
  .center{
    flex: 2;
    padding: 4px;
    display: flex;
    flex-direction: column;
    .monitor{
      height: 75%;

    }
    .device{
      height: 25%;

    }
  }
  .right{
    flex: 1;
    padding: 4px;

  }
  .alarm{
    flex: 3
  }

}
::v-deep .ant-modal-content{
  //background-color: #f5222d;
  border: 5px solid #f5222d90;
}
</style>
