<template>
  <div class="monitor">
    <div class="monitor-box">
      <div v-show="textKey === 1" style="display: flex;justify-content: center; font-weight: bold">实时监控</div>
      <div v-show="textKey === 2">录像</div>
    </div>
    <div >
      <template v-if="type === 100">
        <div class="amap-page-container">
          <div style="color: white; position: absolute; top: 85px; right: 15px; z-index: 9999">
            <div>
              <a-button type="primary" @click="initPosition(1)">单位定位</a-button>
            </div>
            <div style="margin-top: 15px">
              <a-button type="primary"  @click="initPosition(2)">设备定位</a-button>
            </div>
          </div>

          <el-amap  :center="center" :zoom="zoom"
                     class="amap-demo"
                    @init="init"
                    mapStyle="amap://styles/darkblue">
            <el-amap-marker
                v-for="(marker, index) in massPoints"
                :key="index"
                :position="marker.position"
                :zIndex="5"
                icon="https://jc-app-store-1258271165.cos.ap-guangzhou.myqcloud.com/icon%2Fmark_normal.png"></el-amap-marker>
<!--            <el-amap-marker-->

<!--                :position="marker.position"-->

<!--                :zIndex="5"-->
<!--                icon="https://jc-app-store-1258271165.cos.ap-guangzhou.myqcloud.com/icon%2Fmark_normal.png"></el-amap-marker>-->
          </el-amap>
        </div>
      </template>
      <template v-if="currentUrl && type === 1">
        <div style="display:flex;">
<!--          {{device}}-->
          <a-button v-if="device.modelType === 'TD-5422E1-VT1'"  type="link" @click="changeHot(1, '.live.flv')">切换{{ currentChannel === 1 ? '热成像' : '视频' }}</a-button>
          <div style="margin-left: auto">摄像头状态：{{monitorStatus ? "在线":"离线"}}</div>
        </div>

        <jessibucaDemo :video-url="currentUrl"  @monitorStatus="getMonitorStatus"></jessibucaDemo>
      </template>
      <imageVideo :video-url="playVideoUrl" v-if="playVideoUrl && type === 2"></imageVideo>
      <jcWebrtcByBoli ref="webrtc" :video-url="playVideoUrl"  v-if="playVideoUrl && type === 3" :status-show="true"></jcWebrtcByBoli>
      <div style="display:flex; justify-content: center">
        <video :src="playVideoUrl" v-if="playVideoUrl && type === 4" width="900" height="500" controls autoplay muted style="object-fit: fill"></video>
      </div>
      <div class="content1">
        <a-card class="item" v-for="(item) in orderDevice" :key="item.id">
          <div  :title="item.deviceName">名称: {{item.deviceName.replace("摄像头", "...")}}</div>
          <!--         <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
          <div style=" margin-top: 5px">设备指令: </div>
          <div style="display: flex; margin-top: 8px">
            <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">
              <template  v-if="item.needPassword === 1">
                <a-popconfirm
                    title="下发指令是一个危险的操作"
                    ok-text="下发"
                    cancel-text="取消"
                    @confirm="sendOrder(item, it)"
                    @cancel="cancelOrder"

                >
                  <a-tag color="green" >
                    {{ it.name }}
                  </a-tag>
                </a-popconfirm>
              </template>
              <template v-else>
                <a-tag color="green" @click="sendOrder(item, it)">
                  {{ it.name }}
                </a-tag>
              </template>





            </div>
          </div>
        </a-card>
      </div>

<!--      <iframe width="910" height="500"  id="myframe" :src="playVideoUrl" v-if="playVideoUrl && type === 3"></iframe>-->
      <a-empty style="height: 610px;background-color: #0f1325" description="暂无视频" v-if="type === 0"/>
      <a-modal
          style="z-index: 9999!important;"
          width="1430px"
          :visible="videoDialogVisible"
          @cancel="videoCancel"
          @ok="videoOk"
          :footer="null"
          :centered="true"
          :maskClosable="false"
      >

        <iframe width="1366" height="768"  id="myframe" :src="playVideoUrl" ></iframe>


      </a-modal>

    </div>


  </div>
</template>

<script>
import jessibucaDemo from "@/views/monitor/JessibucaDemo.vue";
import imageVideo from "@/views/monitor/ImageVideo.vue";
import jcWebrtcByBoli from "@/views/monitor/JcWebrtcByBoli.vue";
import {axiosServer} from "@/utils/request";
import {getMaxBoundsPointer, sendOrder} from "@/utils/commenRequset";

export default {
  name: "JcMonitor",
  components: {

    jessibucaDemo,
    imageVideo,
    jcWebrtcByBoli,

  },
  props:["playVideoUrl", 'device', 'comId', 'comPos'],
  data(){
    return{
      textKey: 1,
      monitorUrl: null,
      type: 0,
      webRtcServer: null,
      monitorStatus: false,
      videoDialogVisible: false,
      center: [114.12374, 22.670216],
      zoom: 17,
      marker: {
        position: [114.12374, 22.670216]
      },
      massPoints: [],
      orderDevice:[],
      currentUrl: null,
      currentChannel: 1, //1普通2热成像
      companyPos: [],
      devicePos: [],
      currentUsePos: 1,
      map: null,
    }
  },
  mounted() {
    // let type = localStorage.getItem("usePos");
    // if (type === 2){
    //   // 执行显示设备定位
    //   this.initDevicePos();
    // }
  },
  methods:{
    sendOrder,
    init(o) {
      this.map = o;
      console.log("intMap()")
      // if (Number(localStorage.getItem("usePos")) === 2){
      //   this.initDevicePos();
      // }else {
      //   this.clickCompanyChange();
      // }
    },
    getMonitorStatus(status){

      this.monitorStatus = status;

    },
    videoCancel(){
      this.videoDialogVisible = false;
    },
    videoOk(){
      this.videoDialogVisible = false;
    },
    webrtcClose(){
      if (this.type === 3){
        this.$refs.webrtc.closeVideo();
      }

    },
    clickCompanyChange(pos){
      console.log('position',this.comPos)
      if (pos[0] === 0 ||pos[1] === 0){
        this.type = 0;
        return;
      }
      this.massPoints = [];
      this.massPoints.push({
        position: pos,
      })
      this.marker.position =  pos;
      this.companyPos = pos;
      this.center = pos;
      this.type = 100;
    },
    initRelationDevice(deviceId){
      this.orderDevice = [];
      axiosServer({
        url: "/jc/jcGeneral/relation/device/one",
        params: {
          deviceId:deviceId
        },
        type:"POST",
      }).then(res => {
        console.log("initRelationDevice：",res);

        this.orderDevice = res.result;
      })
    },

    cancelOrder(){

    },
    changeHot(type, mediaType){
      // wss://video.jckjcn.com:443/rtp/10059254044060200001_34020000001310000002.live.flv

      let urlSplit = this.currentUrl.split("/");
      // console.log("changeHot", urlSplit)
      let nums = urlSplit[4].match(/\d+/g);

      let hotChannel = nums[1] === "34020000001340000001" ? "34020000001310000002":"34020000001340000001";
      if (nums[1] === "34020000001340000001"){
        this.currentChannel = 2;
      }else {
        this.currentChannel = 1;
      }
      if (type === 1){
        // console.log("changeHot type1",this.currentUrl)

        let oldChannelStr= nums[0] +"_" + nums[1]  + mediaType;
        let newChannelStr = nums[0] + "_" + hotChannel + mediaType;
        console.log(oldChannelStr, newChannelStr)
        this.currentUrl = this.currentUrl.replace(oldChannelStr, newChannelStr);
        // console.log("changeHot type1", this.currentUrl)
      }



    },
    initPosition(type, comPos){
      localStorage.setItem("usePos", type);
      this.massPoints = [];

      if (type === 1){
        // 执行显示单位定位
        this.clickCompanyChange(this.comPos)
      }
      if (type === 2){

        // 执行显示设备定位
        this.initDevicePos();
      }
    },
    initDevicePos(){
      this.massPoints= [];
      this.type = 100;
      axiosServer({
        url: "/jc/jcGeneral/get/device/location?companyId=" + this.comId,
        type: "GET"
      }).then(res => {
        console.log(res)
        res.result.forEach((item) => {
          this.massPoints.push({
            position: [item.lng, item.lat],
            id: item.id
          })
        })
        if (res.result.length > 1){
          let maxLocations = getMaxBoundsPointer( this.massPoints.map(item => item.position))
          console.log("maxLocations",maxLocations);
          // 取区间的 1/4 作为地图的边界
          let lngGap = (maxLocations.max[0] - maxLocations.min[0]) / 4;
          let latGap = (maxLocations.max[1] - maxLocations.min[1]) / 4;
          // 新的区域极点坐标
          // eslint-disable-next-line no-undef
          let min = new AMap.LngLat(maxLocations.min[0] - lngGap, maxLocations.min[1] - latGap);
          // eslint-disable-next-line no-undef
          let max = new AMap.LngLat(maxLocations.max[0] + lngGap, maxLocations.max[1] + latGap);

          // eslint-disable-next-line no-undef
          let bounds = new AMap.Bounds(min, max);
          this.map.setBounds(bounds)
          console.log(bounds)
        }

        if (res.result.length === 1){
          // thi(bounds)
          // eslint-disable-next-line no-undef
          this.map.setCenter( new AMap.LngLat(res.result[0].lng, res.result[0].lat));
        }

        if (res.result.length === 0){
          this.type = 0;
        }
        console.log("initDevicePos",this.massPoints,this.type)
      })
    }
},
  watch: {

    playVideoUrl: {
      handler(newVal) {
        this.monitorStatus = false;
        console.log("watch",newVal)
        if (newVal){
          if (newVal.includes("120.226.39.61")){
            this.type = 0;
            // this.videoDialogVisible = true;
            window.open(newVal, '_blank', '');
            return;
          }
          if (newVal.includes("36.213.16.96")){
            this.type = 1;
            this.currentUrl = newVal;
            return;
          }
          if (newVal.includes("webrtc")){
            this.type = 3;
            return;
          }

          if (newVal.includes("main_stream.jpeg")){
            this.type = 2;
          }else {
            // 默认置1
            this.type = 1;
            this.currentUrl = newVal;
          }
        }else {
          this.type = 0;
        }

        // console.log("jcMonitor videoUrl",newVal, oldVal)
        // this.$nextTick(() => {
        //   this.play(val);
        // })
      },
      immediate: true
    }
  },
}
</script>
<style scoped lang="scss">
.monitor{
  padding: 15px;
  height: 700px;
  .monitor-box{
    font-size: 18px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}
#myframe{
  //transform: scale(2, 2);
}
.amap-demo {
  padding: 15px;
  height: 620px;
  width: 100%;
}

.content1{
  margin-top: 8px;
  display: flex;
  ::v-deep .ant-card-body{
    padding: 0;
  }
  .item{
    padding: 8px;
    //margin-top: 10px;
    margin-left: 5px;
    margin-right: 10px;
    display: inline-block;
    background-color: rgb(13, 27, 88);
    color: #fff;
    min-width: 150px;
    height: 95px;
  }
}
</style>