<template>


  <div class="device-box">
    <template v-if="deviceList.length === 0">
      <a-empty style="height: 100%;background-color: #0f1325" description="暂无设备" />
    </template>

    <div class="device" v-else >

      <template >
        <div class="content1">
          <a-card class="item" v-for="(item) in deviceList" :key="item.id">
            <div  >设备名称: {{item.deviceName}}</div>
            <div  >客户机状态: {{getDetectorState(item.detectorState)}}</div>
            <!--         <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
            <div style=" margin-top: 5px">设备指令: </div>
            <div style="display: flex; margin-top: 8px">
              <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">

                <template  v-if="item.needPassword === 1">
                  <a-popconfirm
                      title="下发指令是一个危险的操作"
                      ok-text="下发"
                      cancel-text="取消"
                      @confirm="sendOrder(item, it)"
                      @cancel="cancel"

                  >
                    <a-tag color="green" >
                      {{ it.name }}
                    </a-tag>
                  </a-popconfirm>
                </template>
                <template v-else>
                  <a-tag color="green" @click="sendOrder(item, it)">
                    {{ it.name }}
                  </a-tag>
                </template>
              </div>
            </div>
          </a-card>
        </div>
      </template>

    </div>

  </div>

</template>

<script>
import {axiosServer} from "@/utils/request";
import {sendOrder} from "@/utils/commenRequset";
import {parseDeviceStatus} from "@/utils/DetectorValueParser";

export default {
  name: "JcDevice",
  // props: ["monitorDeviceName"],
  data(){
    return{
      deviceList: [],
      visible: false,

    }
  },
  methods: {

    sendOrder,
    getDetectorState(value){
      return  parseDeviceStatus(value);
    },
    initDevice1(comId){
      if (!comId){
        return;
      }
      this.deviceList = [];
      axiosServer({
        url: "/jc/jcGeneral/relation/device1",
        params: {
          comId: comId || 0,
        },
        type:"POST",
      }).then(res => {
        console.log("initDevice1：",res);
        this.deviceList = res.result;
      })
    },
    // sendOrder(data, order){
    //
    //   if (order.type === 1){
    //     this.$message.success("执行自动下发")
    //     return;
    //   }
    //   let config = {};
    //   if (data.actionType == 1){
    //     config.ip = data.ip;
    //     config.operationCode = order.val;
    //   }
    //   if (data.actionType === 2){
    //     config.ip = data.ip;
    //     config.eqSwitch = order.val;
    //     config.equipId =  data.equipid;
    //   }
    //
    //   axiosServer({
    //     url: "/jc/jcGeneral/send/order",
    //     params: {
    //       ...config
    //     },
    //     type:"POST",
    //   }).then(res => {
    //     console.log("sendOrder：",res);
    //     if (res.code === 200){
    //       this.$notification.success({
    //         message:res.message
    //       });
    //     }else {
    //       this.$notification.error({
    //         message:res.message
    //       });
    //     }
    //
    //   })
    //
    //   console.log(data, order, config)
    // },
    cancel(){

    },
    hideModal(){

    },

    openConfirm(){

    }
  }
}
</script>



<style scoped lang="scss">
.device-box{
  height: 220px;
  padding: 15px;
}
.device{

  .content1{
    //display: flex;

    overflow: hidden;
    overflow-y: scroll;
    height: 200px;
    width: 900px;
  }
  .content1::-webkit-scrollbar {
    width: 0px;

  }
  .content1::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(0,0,0,0.2);
  }
  .content1::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0,0,0,0.1);
  }
}
.content {
  //background: rgb(196, 196, 196);
  width: 900px;
  height: 150px;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.item {
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  background-color: rgb(13, 27, 88);
  color: #fff;
  min-width: 150px;
  height: 120px;
}
/* 横向滚动条样式 */
.content::-webkit-scrollbar {
  width: 5px; /* 设置滚动条宽度 */
  height: 8px;
}
.content::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滑动块颜色 */
  border-radius: 5px; /* 设置滑动块圆角 */
}
.content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 设置滑道颜色 */
}
.content::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置鼠标悬停时滑动块颜色 */
}
.content::-webkit-scrollbar-thumb:active {
  background-color: #000; /* 设置滑动块按下时颜色 */
}
::v-deep .ant-card-body{
  padding: 8px;

}

</style>